import PropTypes from 'prop-types'
import {useRef, useEffect} from 'react'
import styled from 'styled-components'
import dialogPolyfill from 'dialog-polyfill'
import CloseIcon from '../../../assets/icons/close-icon.svg'


const StyledDialog = styled.dialog`
  position: fixed;
  z-index: 1;
  border: 0;
  background-color: white;
  max-width: 60rem;
`

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: end;

  svg {
    cursor: pointer;

    path,
    rect {
      stroke: ${({theme}) => theme.colors.secondary};
    }
  }
`

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;

  @supports (backdrop-filter: blur(2px)) {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
  }
`

const BlurredModal = ({open, onClose, children, ...props}) => {
  const dialog = useRef(null)
  const getRef = async (ref) => {
    if (typeof window !== 'undefined') {
      // The library dialog-polyfill doesn't have build in support for SSR. See issue:
      // https://github.com/GoogleChrome/dialog-polyfill/issues/169
      if (ref && !dialog.current) dialogPolyfill.registerDialog(ref)
      dialog.current = ref
    }
  }
  useEffect(() => {
    const self = dialog.current
    if (!self || self.open === open) return
    if (open) {
      self.showModal()
    } else {
      self.close()
    }
  }, [open, dialog])

  const handleCancel = (e) => {
    e.preventDefault()
    if (onClose) onClose(e, dialog.current)
  }

  if (!open) return null

  return (
    <StyledBackdrop>
      <StyledDialog
          ref={getRef}
          onCancel={handleCancel}
          {...props}
      >
        <CloseIconWrapper>
          <CloseIcon onClick={handleCancel} />
        </CloseIconWrapper>
        {children}
      </StyledDialog>
    </StyledBackdrop>
  )
}

BlurredModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default BlurredModal
